<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row">
      <div class="col-12">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          comunicazioni di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        bacheca_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Non sono presenti comunicazioni.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link to="/bacheca" v-if="isEnabled('fnLinkBachecaCompleta')">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-arrow-left fs-6"></i>
          Indietro
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
      </span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="bacheca_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsBacheca"
        @current-change="setCurrentPageBacheca"
      >
        <template v-slot:cell-img_doc="{ row: data }">
          <!-- <img :src="data.img_doc" style="width: 2rem"/> -->
          <!-- <PreviewUploadedFile
            :name="data.fname"
            tag="div"
            :noText="true"
            @downloadFile="downloadDocument(data.item_id, data.fname)"
          /> -->
          <PreviewDaEstensione
            :estensione="
              data.fname.substring(
                data.fname.lastIndexOf('.') + 1,
                data.fname.length
              )
            "
            @imageClick="downloadDocument(data.item_id, data.fname)"
          />
        </template>
        <template v-slot:cell-tipo_documento="{ row: data }"
          >{{ data.tipo_documento }}
        </template>
        <template v-slot:cell-titolo="{ row: data }"
          ><div class="tab-long">
            {{ data.titolo }}
            <!-- <br />{{ data.sommario }} <br />
            [{{ data.province }}] -->
          </div>
        </template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long">
            {{ data.descrizione }}
            <!-- <br />{{ data.sommario }} <br />
            [{{ data.province }}] -->
          </div>
        </template>
        <template v-slot:cell-data="{ row: data }"
          >{{ useFormatDate(data.data) }}
        </template>
      </Datatable>
    </div>
    <br />
  </div>
</template>

<script>
import { ref, computed, onUpdated } from "vue";
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { useFormatDate } from "@/composables/formatDate";
import { alertFailed } from "@/composables/swAlert";
import { downloadElement } from "@/requests/downloadFiles";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "TableBachecaPublic",
  components: {
    Datatable,
    Loading,
    PreviewDaEstensione,
  },
  emits: ["getBachecaList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsBacheca = (e) => {
      store.commit("setFetchRowsBacheca", e);
      emit("getBachecaList");
    };
    const setCurrentPageBacheca = (page) => {
      store.commit("setCurrentPageBacheca", page);
      emit("getBachecaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnBacheca", columnName);
      store.commit("setSortOrderBacheca", order);
      emit("getBachecaList");
    };

    const tableHeader = ref([
      {
        key: "img_doc",
        sortable: false,
      },
      {
        name: "Tipo",
        key: "tipo_documento",
      },
      {
        name: "Oggetto",
        key: "titolo",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Data",
        key: "data",
      },
    ]);
    const disabled = ref(false);
    const selectedDocId = ref(false);

    const downloadingFile = ref(false);
    const downloadDocument = async (id, estensione) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(id, "bacheca", estensione);
        downloadingFile.value = false;
      }
    };

    const isLoading = ref(false);

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      bacheca_list: computed(() =>
        store.getters.getStateFromName("resultsbacheca_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedbacheca_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordbacheca_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusbacheca_list")
      ),

      currentPage: computed(() => store.getters.currentPageBacheca),
      rowsToSkip: computed(() => store.getters.rowsToSkipBacheca),
      fetchRows: computed(() => store.getters.fetchRowsBacheca),
      sortColumn: computed(() => store.getters.sortColumnBacheca),
      sortOrder: computed(() => store.getters.sortOrderBacheca),
      setFetchRowsBacheca,
      setCurrentPageBacheca,
      setSortOrderColumn,
      tableHeader,
      disabled,
      selectedDocId,
      useFormatDate,
      downloadDocument,
      isLoading,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,
    };
  },
};
</script>

<style></style>
